import { Button, Divider, Grid, Group, Input, Paper, Space, Stepper, Text } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AccountApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { CheckboxField } from "../form/check-box";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";

type formData = {
  bankName: string;
  number: string;
  isDefault: boolean;
  shortName: string;
};

const schema = yup.object({
  bankName: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  number: yup
    .string()
    .matches(/^[0-9]{10}$/, "Зөвхөн данс оруулна уу.")
    .required("Заавал бөглөнө!"),
  shortName: yup.string().required("Заавал бөглөнө!"),
});

const SettingsForm = ({ action, reload, onClose }: any) => {
  const [step, setStep] = useState<number>(action[1] ? 2 : 1);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { bankNames, currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = useState<boolean>(false);
  const [bankAccount, setBankAccount] = useState<any>({
    accountNumber: action[1] && action[1]?.number,
    accountName: action[1] && action[1]?.name,
    currency: action[1] && action[1]?.currency,
    shortName: action[1] && action[1]?.shortName,
    isDefault: action[1] && action[1]?.isDefault ? action[1]?.isDefault : false,
  });

  const [data] = React.useState<formData>({
    bankName: (action[1] && action[1]?.bankName) || "",
    number: (action[1] && action[1]?.number) || "",
    isDefault: action[1] && action[1]?.isDefault,
    shortName: action[1] && action[1]?.shortName,
  });

  const onSubmit = async (values: formData) => {
    setLoading(true);
    try {
      if (action[0] === "new") {
        await AccountApi.create({
          bankName: values.bankName,
          number: values.number,
          shortName: values.shortName,
          isDefault: values.isDefault ? true : false,
        });
      }
      if (action[0] === "edit" && action[1]) {
        await AccountApi.update(action[1].id, {
          shortName: values.shortName,
          isDefault: values.isDefault ? true : false,
        });
      }
      message.success("Таны данс амжилттай холбогдлоо.");
      setLoading(false);
      reload();
      onClose();
    } catch (error: any) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const check = async (value: any) => {
    setLoading(true);
    try {
      let res = await AccountApi.check({
        bankName: value.bankName,
        number: value.number,
      });
      if (res?.url) {
        message.error("Та эхлээд банкаар нэвтэрнэ, ахин оролддоно уу.");
      } else {
        setBankAccount(res);
        setStep(2);
      }

      setLoading(false);
    } catch (error: any) {
      console.log("error: ", error);
      message.error(error?.message || "Та мэдээллээ зөв эсэхийг шалгана уу.");
      setLoading(false);
      setStep(1);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, errors }) => {
        return (
          <Paper pb={"lg"}>
            <Divider mb="lg" />
            <Stepper iconSize={32} active={step}>
              <Stepper.Step label="Данс баталгаажуулах" description="Бүртгэлтэй данс оруулна уу" />
              <Stepper.Step label="Данс холбох" description="Бизнес тооцооны данс холбох" />
            </Stepper>
            <Space h={"lg"} />
            <Grid>
              {step === 1 && (
                <>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
                    <SelectField
                      disabled={!!data.bankName}
                      options={bankNames
                        .filter((item: any) => item.code === "GOLOMT" || item.code === "BOGD" || item.code === "DEHUB")
                        .map((c: any) => ({ label: c?.name, value: c?.code }))}
                      name="bankName"
                      label="Банк:"
                      placeholder="Банкны нэр"
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
                    <TextField name="number" label="Дансны дугаар:" placeholder="Дансны дугаар оруулах" />
                  </Grid.Col>
                  <Grid.Col>
                    <Group align="right">
                      <Button
                        type="submit"
                        disabled={errors.number || errors.bankName || !values.number || !values.bankName || values.number.length !== 10}
                        rightSection={<IconArrowRight />}
                        loading={loading}
                        onClick={async () => {
                          if (!errors.number && !errors.bankName) {
                            await check({
                              bankName: values.bankName,
                              number: values.number,
                            });
                          }
                        }}>
                        {"Дараагийнх"}
                      </Button>
                    </Group>
                  </Grid.Col>
                </>
              )}
              {step === 2 && (
                <>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Партнер нэр" required>
                      <Text size="sm" c="indigo" fw={500}>
                        {user?.partner?.businessName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Татвар төлөгчийн дугаар" required>
                      <Text size="sm" c="indigo" fw={500}>
                        {user?.partner?.regNumber || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Бизнесийн нэр" required>
                      <Text size="sm" c="indigo" fw={500}>
                        {user?.currentBusiness?.profileNameEng || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Огноо, цаг" required>
                      <Text size="sm" c="indigo" fw={500}>
                        {user?.createdAt ? dateTimeFormat(`${user.createdAt}`) : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Бүртгэсэн хэрэглэгч" required>
                      <Text size="sm" c="indigo" fw={500}>
                        {user?.lastName ? user?.lastName[0] + "." : ""}
                        {user?.firstName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Хэрэглэгчийн мэдээлэл" required>
                      <Text size="sm" c="indigo" fw={500}>
                        {user?.email || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Divider />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Банк:">
                      <Text size="sm" c="indigo" fw={500}>
                        {bankNames.find((e) => e.code === values.bankName)?.name ?? "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Дансны дугаар:">
                      <Text size="sm" c="indigo" fw={500}>
                        {bankAccount?.accountNumber || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Дансны нэр:">
                      <Text size="sm" c="indigo" fw={500}>
                        {bankAccount?.accountName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Валют:">
                      <Text size="sm" c="indigo" fw={500}>
                        {currencies.find((item: IReference) => item.code === bankAccount.currency)?.name || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextField name="shortName" label="Дансны товч нэр:" placeholder="Дансны товч оруулах" disabled={action[0] === "details"} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <CheckboxField label="Үндсэн данс эсэх" name="isDefault" disabled={action[0] === "details"}>
                      Тийм
                    </CheckboxField>
                  </Grid.Col>
                  <Grid.Col>
                    <Divider my="sm" />
                    <Group align="right" justify="space-between">
                      <Button onClick={() => onClose()} variant="default">
                        Болих
                      </Button>
                      <Button loading={loading} type="submit" hidden={action[0] === "details"}>
                        Хадгалах
                      </Button>
                    </Group>
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Paper>
        );
      }}
    </Form>
  );
};

export default SettingsForm;
